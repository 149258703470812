import React, { useState } from "react";
import { Menu } from "antd";
import { Link, useRouteMatch } from "react-router-dom";
//traemos el context
import { useAuth } from "../../authentication";
//-----------------
import CustomScrollbars from "util/CustomScrollbars";
import SidebarLogo from "./SidebarLogo";
import UserProfile from "./UserProfile";
import AppsNavigation from "./AppsNavigation";
import {
  NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  THEME_TYPE_LITE,
} from "../../constants/ThemeSetting";
import IntlMessages from "../../util/IntlMessages";
import { useSelector } from "react-redux";
import SubMenu from "antd/lib/menu/SubMenu";
import MenuItem from "antd/lib/menu/MenuItem";

const SidebarContent = ({ sidebarCollapsed, setSidebarCollapsed }) => {
  const { navStyle, themeType } = useSelector(({ settings }) => settings);
  const pathname = useSelector(({ common }) => common.pathname);

  const getNoHeaderClass = (navStyle) => {
    if (
      navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR ||
      navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR
    ) {
      return "gx-no-header-notifications";
    }
    return "";
  };

  //traemos los datos del usuario logueado
  const { authUser } = useAuth();
  //-----------
  const selectedKeys = pathname.substr(1);
  const defaultOpenKeys = selectedKeys.split("/")[1];

  const getNavStyleSubMenuClass = (navStyle) => {
    if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR) {
      return "gx-no-header-submenu-popup";
    }
    return "";
  };

  const goToAdminPage = () => {
    window.open("https://tracking-admin.stracontech.com/", "_self");
  };

  return (
    <>
      <SidebarLogo
        sidebarCollapsed={sidebarCollapsed}
        setSidebarCollapsed={setSidebarCollapsed}
      />
      <div className="gx-sidebar-content">
        <div
          className={`gx-sidebar-notifications ${getNoHeaderClass(navStyle)}`}
        >
          <UserProfile />
          {/* <AppsNavigation /> */}
        </div>
        <CustomScrollbars className="gx-layout-sider-scrollbar">
          <Menu
            defaultOpenKeys={[defaultOpenKeys]}
            selectedKeys={[selectedKeys]}
            theme={themeType === THEME_TYPE_LITE ? "lite" : "dark"}
            mode="inline"
          >
            {authUser.is_active ? (
              <>
                {/* <Menu.Item key="dashboard">
                <Link to="/dashboard">
                  <i className="icon icon-dasbhoard" />
                  <span><IntlMessages id="sidebar.dashboard" /></span>
                </Link>
              </Menu.Item> */}

                <Menu.Item key="tracking">
                  <Link to="/tracking">
                    <i className="icon icon-location" />
                    <span>Tracking</span>
                  </Link>
                </Menu.Item>

                <Menu.Item key="paths">
                  <Link to="/paths">
                    <i className="icon icon-timeline" />
                    <span>Paths</span>
                  </Link>
                </Menu.Item>

                <Menu.Item onClick={() => goToAdminPage()}>
                  <i className="icon icon-setting" />
                  <span>Admin</span>
                </Menu.Item>

                {/* <SubMenu
                  key="Ajustes"
                  popupClassName={getNavStyleSubMenuClass(navStyle)}
                  title={
                    <span>
                      {" "}
                      <i className="icon icon-widgets" />
                      <span>
                        <IntlMessages id="sidebar.dashboard" />
                        Configuración
                      </span>
                    </span>
                  }
                >
                  <Menu.Item key="setting">
                    <Link to="/settings">
                      <i className="icon icon-widgets" />
                      <span>Ajustes</span>
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="usuarios">
                  <Link to="/usuarios">
                    <i className="icon icon-widgets" />
                    <span>Usuarios</span>
                  </Link>
                </Menu.Item>
                </SubMenu> */}
              </>
            ) : (
              <>
                <Menu.Item key="sample">
                  <Link to="/sample">
                    <i className="icon icon-widgets" />
                    <span>
                      <IntlMessages id="sidebar.samplePage" />
                    </span>
                  </Link>
                </Menu.Item>
              </>
            )}
          </Menu>
        </CustomScrollbars>
      </div>
    </>
  );
};

export default React.memo(SidebarContent);
