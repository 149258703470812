import React from "react";
import { Avatar, Popover } from "antd";
import { useAuth } from "../../authentication";
import { useHistory } from "react-router-dom";

const UserInfo = () => {
  const { authUser, userSignOut } = useAuth();
  const history = useHistory();

  const onLogoutClick = () => {
    userSignOut(() => {
      window.open("https://tracking-admin.stracontech.com/logout/", "_self");
    });
  };

  const userMenuOptions = (
    <ul className="gx-user-popover">
      <li onClick={onLogoutClick}>Logout</li>
    </ul>
  );

  return (
    <Popover
      overlayClassName="gx-popover-horizantal"
      placement="bottomRight"
      content={userMenuOptions}
      trigger="click"
    >
      <Avatar src={authUser.picture} className="gx-avatar gx-pointer" alt="" />
    </Popover>
  );
};

export default UserInfo;
