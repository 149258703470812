import axios from "axios";

export const straconClient = axios.create({
  baseURL: `https://tracking-admin.stracontech.com/api/`, //YOUR_API_URL HERE
  headers: {
    "Content-Type": "application/json",
    Authorization: process.env.REACT_APP_TOKEN_API_STRACON,
  },
});

//"Accept, Content-Type, Content-Length, Accept-Encoding, Grpc-Metadata-Authorization"

/*
'Content-Length':'',
    'Accept-Encoding':'',
    'Grpc-Metadata-Authorization':'',
*/
